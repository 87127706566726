import { AppBar, Toolbar, Box, Stack, Drawer, List, ListItem, Typography, } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Button } from "@mui/material";
import Image from "next/image";
import { useRouter } from "next/router";
import GitbookIcon from "../base/icons/GitbookIcon";
import ShowRoomIcon from "../base/icons/ShowRoomIcon";
import MenuIcon from '@mui/icons-material/Menu';
import { useContext, useEffect, useState } from "react";
import WalletIcon from "../base/icons/WalletIcon";
import LogoutIcon from '@mui/icons-material/Logout';
import { LogoutPlayer } from "../../api/playFab";
import { UserContext } from "../../providers/UserContext";
import WindowsIcon from "../base/icons/WindowsIcon";
import AppleIcon from '@mui/icons-material/Apple';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { getDownloadGameInfo } from "../../lib/device-detector";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import { setCookie, getCookie } from "cookies-next";
// import { useAccount } from 'wagmi'

const NavBar = styled(AppBar)`
    // height: 86px;
    background-Color: ${props => props.theme.vars.palette.neutral['900']};
    box-shadow: none;
    border-bottom: 1px solid ${props => props.theme.vars.palette.neutral['800']};

    ${props => props.theme.breakpoints.up("sm")} {
        padding-left: ${props => props.theme.spacing(10)};
        padding-right: ${props => props.theme.spacing(10)};
    }

    ${props => props.theme.breakpoints.up("md")} {
        padding-left:  ${props => props.theme.spacing(20)};
        padding-right:  ${props => props.theme.spacing(20)};
    } 

    
    .toolBar {
        display: flex;
        height: 100%;
        max-width: ${props => props.theme.maxPageWidth};
        margin: ${props => props.theme.spacing(0, 'auto')};
        padding-top: ${props => props.theme.spacing(2.66)};
        padding-bottom: ${props => props.theme.spacing(4.16)};
        width: 100%;

        ${props => props.theme.breakpoints.up("lg")} {
            max-width: ${props => props.theme.maxPageWidth};
            margin: ${props => props.theme.spacing(0, 'auto')};
            padding-left: ${props => props.theme.spacing(20)};
            padding-right: ${props => props.theme.spacing(20)};
        }

        .navButton {
            display: none;
            ${props => props.theme.breakpoints.up("sm")} {
                display: flex;
            }
        }

        .menuButton {
            display: none;
            ${props => props.theme.breakpoints.down("sm")} {
                display: flex;
            }
        }
    }
`;

const Logo = styled(Image)`
    cursor: pointer;
`;

const NavButton = styled(Button)`
    width: 48px;
    height: 48px;
    min-width: unset;
` as typeof Button;

const DrawerMenuButton = styled(Button)`
    margin-bottom: ${props => props.theme.spacing(3)};

    span {
        padding-left: ${props => props.theme.spacing(1)};
        padding-right: ${props => props.theme.spacing(1)};
    }
`;

const DrawerStyled = styled(Drawer)`
    .itemsList {
        padding-top: 36px;
    }

    .closeButton {
        position: absolute;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.25);;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-weight: 600;
        font-size: 20px;
        top: 10px;
        right: 10px;
    }
`;

const MenuContainer = styled(Grid)`
    height: 60px;
    background-Color: ${props => props.theme.vars.palette.neutral['900']};
    border-bottom: 1px solid ${props => props.theme.vars.palette.neutral['800']};
`;

const Menu = styled(Box)`
    max-width: 920px;
    margin: 0 auto;
    overflow: auto;
    white-space: nowrap;
    text-align: center;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const MenuItem = styled(Button)`
    text-align: center;
    display: inline-block;
    color: white;
    padding: 14px;

    &.active {
        color: ${props => props.theme.vars.palette.brand['100']};
    }

    svg {
        vertical-align: middle;
        margin-right: ${props => props.theme.spacing(2)};
    }
`;

const ProfileContainer = styled(Box)`
    background: var(--matic, linear-gradient(135deg, #7C4DFF 0%, #B388FF 100%));
    color: white;
    text-align: center;
    padding: ${props => props.theme.spacing(4)};
    display: none;

    div {
        margin-bottom: ${props => props.theme.spacing(3)};
    }
    
    .container {
        position: relative;
        max-width: ${props => props.theme.maxPageWidth};
        margin: 0 auto;
        overflow: hidden;
        padding-right: 30px;

        .closeIcon {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
        }
    }

    &.closed {
        display: none;
    }
    &.open {
        display: block;
    }
`;

const ProfileButton = styled(Button)`
    background: var(--neutral-100, #F5F5F5);
    color: ${props => props.theme.vars.palette.brand['100']};

    &:hover {
        background-color: var(--neutral-100, #F5F5F5);
        opacity: 0.9;
    }
`;

export const PROFILE_BANNER_COOKIE = 'ProfileBanner';

const Navbar = () => {
    const { user }: any = useContext(UserContext);
    const router = useRouter();
    const gitbookUrl = 'https://docs.metananos.com/introduction/meta-nanos';
    const anchor = 'left';
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const isMaintenance = process.env.NEXT_PUBLIC_MAINTENANCE === 'false' ? false : true;
    const [menuItemId, setMenuItemId] = useState(-1);
    const [downloadGameInfo, setDownloadGameInfo] = useState({ url: '', device: '' });
    const [profileOpen, setProfileOpen] = useState(false);

    useEffect(() => {
        router.events.on('routeChangeStart', (url, { shallow }) => {
            applyProfileBannerVisibility(url);
        });
        setDownloadGameInfo(getDownloadGameInfo());
    }, []);
    

    useEffect(() => {
        if (window.location.href.indexOf('/showroom') !== -1) {
            setMenuItemId(0);
        } else if (window.location.href.indexOf('/wallet') !== -1) {
            setMenuItemId(2);
        } else if (window.location.href.indexOf('/leaderboards') !== -1) {
            setMenuItemId(3);
        } else {
            setMenuItemId(-1);
        }
        applyProfileBannerVisibility(window?.location?.href);
    }, [router]);

    const applyProfileBannerVisibility = (url) => {
        const profileCookieValue = getCookie(PROFILE_BANNER_COOKIE);
        const shouldProfileBannerClosed = (profileCookieValue === 'true' || profileCookieValue === true || url?.indexOf('profile') !== -1 || url?.indexOf('connect') !== -1) ? true : false;
        setProfileOpen(!shouldProfileBannerClosed);
    }

    const navigateTo = (path: string) => {
        if (path === 'downloadGameUrl') {
            window.open(downloadGameInfo.url);
        } else if (path === 'logout') {
            LogoutPlayer().subscribe({
                next: () => {
                    if (window) {
                        window.location.href = '/';
                    } else {
                        router.push('/');
                    }
                },
                error: (error) => {
                    console.log('error at logged out');
                }
            });
        } else {
            if (path === 'profile') {
                closeProfile();
            }
            router.push(`/${path}`);
        }
    }

    // open the drawer
    const toggleDrawer = (anchor: any, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    }

    // when user click in one nof the menu items
    const onMenuItemSelected = (menuItemId) => {
        setMenuItemId(menuItemId);
    }

    // close the profile banner
    const closeProfile = () => {
        setCookie(PROFILE_BANNER_COOKIE, true);
        setProfileOpen(false);
    }

    const list = (anchor: any) => (
        <Box className="itemsList"
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 350 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <ListItem key="Gitbook" disablePadding>
                    <DrawerMenuButton variant="contained" sx={{ width: '100%', marginButton: '' }}
                        href={gitbookUrl}
                        data-nano="navbar--gitbook">
                        <GitbookIcon />
                        <Typography variant="xs">Gitbook</Typography>
                    </DrawerMenuButton>
                </ListItem>
                {!isMaintenance &&
                    <>
                        <ListItem key="Showroom" disablePadding>
                            <DrawerMenuButton variant="contained" sx={{ width: '100%', marginButton: '' }}
                                onClick={() => navigateTo('showroom')}
                                data-nano="navbar--showroom">
                                <GitbookIcon />
                                <Typography variant="xs">Showroom</Typography>
                            </DrawerMenuButton>
                        </ListItem>
                        <ListItem key="Signin" disablePadding>
                            <DrawerMenuButton variant="contained" sx={{ width: '100%', marginButton: '' }}
                                onClick={() => navigateTo('downloadGameUrl')}
                                data-nano="navbar--download-game">
                                {downloadGameInfo.device === 'mac' &&
                                    <AppleIcon />
                                }
                                {downloadGameInfo.device === 'windows' &&
                                    <WindowsIcon />
                                }
                                <Typography variant="xs">Download Game</Typography>
                            </DrawerMenuButton>
                        </ListItem>
                        <ListItem key="BuyNano" disablePadding>
                            <DrawerMenuButton variant="contained" sx={{ width: '100%', marginButton: '' }}
                                onClick={() => navigateTo('mint-nano?type=public')}
                                data-nano="navbar--mint-nano">
                                <ShoppingCartIcon />
                                <Typography variant="xs">Buy NANO</Typography>
                            </DrawerMenuButton>
                        </ListItem>
                        {/* {!user.playFab.IsLoggedIn &&
                            <ListItem key="Signin" disablePadding>
                                <DrawerMenuButton variant="contained" sx={{ width: '100%', marginButton: '' }}
                                    onClick={() => navigateTo('sign-in')}
                                    data-nano="navbar--sign-in">
                                    <GitbookIcon />
                                    <Typography variant="xs">Sign in</Typography>
                                </DrawerMenuButton>
                            </ListItem>
                        } */}
                        {/* <ListItem key="Leaderboard" disablePadding>
                            <DrawerMenuButton variant="contained" sx={{ width: '100%', marginButton: '' }}
                                onClick={() => navigateTo('leaderboards')}
                                data-nano="navbar--leaderboard">
                                <WalletIcon />
                                <Typography variant="xs">Leaderboard</Typography>
                            </DrawerMenuButton>
                        </ListItem> */}
                        {user.playFab.isLoggedIn &&
                            <ListItem key="Profile" disablePadding>
                                <DrawerMenuButton variant="contained" sx={{ width: '100%', marginButton: '' }}
                                    onClick={() => navigateTo('profile')}
                                    data-nano="navbar--profile">
                                    <AccountCircleIcon />
                                    <Typography variant="xs">Profile</Typography>
                                </DrawerMenuButton>
                            </ListItem>
                        }
                        {user.playFab.IsLoggedIn &&
                            <ListItem key="Wallet" disablePadding>
                                <DrawerMenuButton variant="contained" sx={{ width: '100%', marginButton: '' }}
                                    onClick={() => navigateTo('wallet')}
                                    data-nano="navbar--wallet">
                                    <WalletIcon />
                                    <Typography variant="xs">Wallet</Typography>
                                </DrawerMenuButton>
                            </ListItem>
                        }
                        {user.playFab.IsLoggedIn &&
                            <ListItem key="Logout" disablePadding>
                                <DrawerMenuButton variant="contained" sx={{ width: '100%', marginButton: '' }}
                                    onClick={() => navigateTo('logout')}
                                    data-nano="navbar--logout">
                                    <LogoutIcon />
                                    <Typography variant="xs">Logout</Typography>
                                </DrawerMenuButton>
                            </ListItem>
                        }
                    </>
                }
            </List>
        </Box>
    );

    return (
        <>
            <NavBar position="static">
                <Toolbar className="toolBar">
                    <Box flexGrow={1}>
                        <Logo priority height={42} width={84} onClick={() => navigateTo('')} src="/images/shared/logo.webp" alt="Meta Nanos logo" />
                    </Box>
                    <Stack direction="row" spacing={2} justifyContent="space-between">
                        {!isMaintenance &&
                            <>
                                {/* {!user.playFab.IsLoggedIn &&
                                    <NavButton className="navButton" sx={{ width: 'unset' }} aria-label="Sign in Button" title={"Sign in"} onClick={() => navigateTo('sign-in')} variant="contained"
                                        data-nano="navbar--sign-in">
                                        Sign in
                                    </NavButton>
                                } */}
                                {user.playFab.IsLoggedIn &&
                                    <NavButton className="navButton" sx={{ width: 'unset' }} aria-label="Profile Button" title={"Profile"} onClick={() => navigateTo('profile')} variant="contained"
                                        data-nano="navbar--profile">
                                        <AccountCircleIcon sx={{ marginRight: '6px' }} />
                                        Profile
                                    </NavButton>
                                }
                                {user.playFab.IsLoggedIn &&
                                    <NavButton className="navButton" sx={{ width: 'unset' }} aria-label="Wallet Button" title={"Wallet"} onClick={() => navigateTo('wallet')} variant="contained"
                                        data-nano="navbar--wallet">
                                        <WalletIcon />
                                    </NavButton>
                                }
                                {user.playFab.IsLoggedIn &&
                                    <NavButton className="navButton" sx={{ width: 'unset' }} aria-label="Logout Button" title={"Logout"} onClick={() => navigateTo('logout')} variant="contained"
                                        data-nano="navbar--logout">
                                        <LogoutIcon />
                                    </NavButton>
                                }
                                <NavButton className="menuButton" sx={{ width: 'unset' }} aria-label="Menu Button" title={"Menu"} onClick={toggleDrawer('left', true)} variant="contained">
                                    <MenuIcon />
                                </NavButton>
                            </>
                        }

                    </Stack>
                    <DrawerStyled anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                        <Box className="closeButton" onClick={toggleDrawer(anchor, false)}>X</Box>
                        {list(anchor)}
                    </DrawerStyled>
                </Toolbar>
            </NavBar >
            {!isMaintenance &&
                <MenuContainer xs={12}>
                    <Menu>
                        <MenuItem href="/showroom" data-nano="navbar--showroom" className={menuItemId === 0 ? 'active' : ''} onClick={() => onMenuItemSelected(0)}>
                            <ShowRoomIcon />Showroom
                        </MenuItem>
                        <MenuItem href={downloadGameInfo.url} data-nano="navbar--download-game">
                            {downloadGameInfo.device === 'mac' &&
                                <AppleIcon />
                            }
                            {downloadGameInfo.device === 'windows' &&
                                <WindowsIcon />
                            }
                            Download game
                        </MenuItem>
                        <MenuItem href="/mint-nano?type=public" data-nano="navbar--mint-nano">
                            <ShoppingCartIcon /> Buy NANO
                        </MenuItem>
                        {/* <MenuItem href="/leaderboards" data-nano="navbar--leaderboard" className={menuItemId === 3 ? 'active' : ''} onClick={() => onMenuItemSelected(2)}>
                            <WalletIcon />Leaderboard
                        </MenuItem> */}
                        <MenuItem href="/wallet" data-nano="navbar--wallet" className={menuItemId === 2 ? 'active' : ''} onClick={() => onMenuItemSelected(2)}>
                            <WalletIcon />Wallet
                        </MenuItem>
                        <MenuItem href={gitbookUrl} data-nano="navbar--gitbook">
                            <GitbookIcon />Gitbook
                        </MenuItem>
                    </Menu>
                </MenuContainer>
            }
            {user.playFab.IsLoggedIn &&
                <ProfileContainer className={profileOpen ? 'profile open' : 'profile closed'}>
                    <Box className="container">
                        <Typography variant="body1" component="div" fontWeight="fontWeightBold">Check out your profile!</Typography>
                        <Typography variant="body1" component="div" textAlign="justify">Welcome! You can top up your account balance here. Once you have enough balance, you can use it to purchase so called 'rumble passes' in the game. Every hour, a new exciting rumble challenge begins. If you have a rumble pass, your races will be automatically counted on the rumble board, and you'll compete for winning big amounts of MATIC</Typography>
                        <ProfileButton onClick={() => navigateTo('profile')} variant="contained" style={{ width: '160px' }}
                            data-nano="navbar--profile"><AccountCircleIcon sx={{ marginRight: '6px' }} /> Go to profile</ProfileButton>
                        <CloseIcon className="closeIcon" onClick={closeProfile} />
                    </Box>
                </ProfileContainer>
            }
        </>
    );
}

export default Navbar;